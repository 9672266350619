import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Container from '../components/container'
import CollectionTile from '../components/CollectionTile'
import { graphql } from 'gatsby'
import { TileGrid } from '../components/TileGrid'
import { Wrapper } from '../components/Wrapper'
import { UpdateHeader } from '../templates/update.js'

class Archive extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const collections = get(this, 'props.data.allContentfulCollection.edges').filter(collection => {
      return collection.node.live
    })

    return (
      <Container>
        <Helmet title={`Archive | ${siteTitle}`}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Wrapper containerPadding>
          <UpdateHeader>
            <div className="breadcrumb">
              <span>Archive</span>
            </div>
          </UpdateHeader>
        </Wrapper>
        <Wrapper containerPadding="true">
          <TileGrid className="collection-grid">
            {collections.map(({ node }) => {
              return <CollectionTile collection={node} key={node.id}></CollectionTile>
            })}
          </TileGrid>
        </Wrapper>
      </Container>
    )
  }
}

export default Archive

export const pageQuery = graphql`
  query ArchiveQuery {
    #SITE METADATA
    site {
      siteMetadata {
        title
      }
    }
    
    allContentfulCollection(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          live
          date(formatString: "MMMM YYYY")
          updates {
            title
            slug
            tags {
              name
              id
            }
            thumbnail {
              sizes(maxWidth: 704, maxHeight: 704) {
                ...GatsbyContentfulSizes
              }
            }
          }
        }
      }
    }
  }
`

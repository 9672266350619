import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { STYLES } from '../styles/constants'

const Tile = styled.div`
  .thumbnail {
    margin-bottom: 16px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 8px;
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 20px;
    line-height: 32px;
  }

  @media (max-width: ${STYLES.BP_SMALL_MAX}) {
    h3 {
      padding: 0 16px;
    }
  }
`;

export default class UpdateTile extends React.Component {

  // trackClick = (update, location) => {
  //   if (this.props.page === 'bookmarks') {
  //     ReactGA.event({
  //       category: 'Bookmarks',
  //       action: `View bookmark (${location})`,
  //       label: update.slug
  //     })
  //   }
  //   else {
  //     ReactGA.event({
  //       category: 'Home',
  //       action: `View update (${location})`,
  //       label: update.slug
  //     })
  //   }
  // }

  render() {
    const { collection } = this.props

    return (
      <Tile className="collection-tile">
        {/* <Link to={`/update/${update.slug}`} onClick={() => this.trackClick(update, 'thumbnail')}> */}
        <Link to={`/archive/${collection.slug}`}>
          <Img alt={collection.title} sizes={collection.updates[0].thumbnail.sizes} className="thumbnail" />
        </Link>
        <div className="title-container">
          {/* <h3><Link to={`/update/${update.slug}`} onClick={() => this.trackClick(update, 'title')}>{update.title}</Link></h3> */}
          <h3><Link to={`/archive/${collection.slug}`}>{collection.title}</Link></h3>
        </div>
      </Tile>
    )
  }
}